import { TSigningPartyDTO, VaultEntityType } from "@/types/dto";
import { useToast } from "@chakra-ui/react";
import { MAX_ADDITIONAL_SIGNERS } from "@equidefi/shared/constants/investments";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import swal from "sweetalert";
import InvestmentClient from "../clients/InvestmentClient";
import { extractErrorMessage } from "../helpers/errors";
import { useInvestmentSignatures } from "./useAgreements";
import { useApi } from "./useApi";

export const useUpdateSigner = (investmentId: string, signerId?: string) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (data: Object) =>
    investmentClient.updateSigner(investmentId, signerId, data)
  );
};

export const useDeleteSigner = (investmentId: string) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (signerId: string) =>
    investmentClient.deleteSigner(investmentId, signerId)
  );
};

export const useCreateSigner = (investmentId: string) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (data: Object) => {
    return investmentClient.addSigner(investmentId, data);
  });
};

export const useSetupSigners = (investmentId: string) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async () => {
    return investmentClient.setupSigners(investmentId);
  });
};

export const useInvestmentSigners = (
  investmentId?: string,
  entityType?: VaultEntityType,
  options: { onSuccess: () => void } = { onSuccess: () => {} }
) => {
  const [initialValues, setInitialValues] = useState<Partial<TSigningPartyDTO>>(
    {}
  );
  const toast = useToast();

  const {
    data,
    refetch: refetchSignatures,
    isFetched,
  } = useInvestmentSignatures(investmentId);

  const setupSigners = useSetupSigners(investmentId as string);

  useEffect(() => {
    if (isFetched && !data?.signing_parties.length) {
      setupSigners.mutateAsync();
      refetchSignatures();
    }
  }, [data?.signing_parties]);

  const signers =
    data?.signing_parties?.filter((sp) => ![0, 1, null].includes(sp.order)) ||
    [];

  const canAddMoreSigners = useMemo(
    () => entityType && signers.length < MAX_ADDITIONAL_SIGNERS[entityType],
    [signers.length, entityType]
  );

  const [signerId, setSignerId] = useState<string | null>();

  const addSigner = useCreateSigner(investmentId as string);
  const editSigner = useUpdateSigner(
    investmentId as string,
    signerId as string
  );
  const deleteSigner = useDeleteSigner(investmentId as string);

  const onSubmit = async (data: TSigningPartyDTO) => {
    try {
      if (initialValues?.email) {
        await editSigner.mutateAsync(data);
      } else {
        await addSigner.mutateAsync(data);
      }
      toast({
        status: "success",
        description: "Successfully saved signer",
      });

      options?.onSuccess?.();
      await refetchSignatures();
      setSignerId(undefined);
      setInitialValues({});
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: extractErrorMessage(e),
      });
      return;
    }
  };

  const onDelete = async (signerId: string) => {
    const status = await swal({
      title: "Delete Signer",
      text: "Are you sure you want to delete the signer?",
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (status) {
      try {
        await deleteSigner.mutateAsync(signerId);
        toast({
          status: "success",
          description: "The signer was deleted successfully.",
        });
      } catch (error) {
        console.error(error);
        toast({
          status: "error",
          description: "Something went wrong deleting the signer",
        });
      }
      refetchSignatures();
    }
  };

  const onDeleteMultipleSigners = async (
    signerIds: string[]
  ): Promise<void> => {
    try {
      await Promise.all(
        signerIds.map((signerId) => deleteSigner.mutateAsync(signerId))
      );

      toast({
        status: "warning",
        description:
          "You can only have two signers when investing as an Individual/Joint, additional signers were removed.",
      });
    } catch (error) {
      toast({
        status: "error",
        description: "An error occurred while deleting signers",
      });
    } finally {
      refetchSignatures();
    }
  };

  const onEdit = async (data: TSigningPartyDTO) => {
    const filteredData = {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      order: data.order,
    };
    setSignerId(data.id);
    setInitialValues(filteredData);
  };

  const onCreate = async () => {
    setSignerId(undefined);
    setInitialValues({});
  };

  return {
    signers,
    canAddMoreSigners,
    initialValues,
    refetchSignatures,
    onSubmit,
    onDelete,
    onDeleteMultipleSigners,
    onEdit,
    onCreate,
  };
};
