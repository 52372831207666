import {Box, Button, Tooltip} from "@chakra-ui/react";

import { Text } from "@equidefi/ui";

import {useCanReinvest, useUserInvestments} from "@/hooks/useInvestments";
import {useNavigate} from "react-router";

export const ReinvestBox = ({ offeringId, offeringSlug }) => {
  const navigate = useNavigate();
  const { data: investments } = useUserInvestments({offering_id: offeringId}, {refetchOnMount: "always"});
  const canReinvest = useCanReinvest(investments);

  return (
    <Box
      borderRadius="lg"
      mt={4}
      p={4}
      bgColor="#cba3ff33"
      borderColor="accent"
      borderWidth="2px"
    >
      <Text my="0" mb="0" fontSize="xs" textTransform="uppercase">
        Invest Again
      </Text>
      <Text as="h3" mb="0" fontWeight="bold" fontSize="lg">
        Want to Invest Again?
      </Text>
      <Text mb="2" textStyle="body">
        You can start a new investment in under 10 minutes!
      </Text>
      <Tooltip
        isDisabled={canReinvest}
        label="You have an investment that is still in progress. When accreditation documents have been approved, reinvesting will be made available!"
        hasArrow
      >
        <Box w="full">
          <Button
            isDisabled={!canReinvest}
            w="full"
            onClick={() => {
              navigate(`/offerings/${offeringSlug}/reinvest`);
            }}
          >
            Start A New Investment
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};
