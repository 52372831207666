import { ApiClient } from "./ApiClient";


class VaultClient extends ApiClient {

  async getVault(vaultId: string) {
    if (!vaultId) return null;

    const response = await this.get(`/v2/vault/${vaultId}`);
    return response.data;
  }

  async getUserVaults(userId: string, status?: string) {
    const response = await this.get(`/v2/vault/user/${userId}`, {
      params: { status },
    });
    return response.data;
  }

  async saveProfile(investmentId: string, data: object) {
    const response = await this.post(`/v2/vault/profile/${investmentId}`, data);
    return response.data;
  }

  async saveAddress(investmentId: string, data: object) {
    const response = await this.post(`/v2/vault/address/${investmentId}`, data);
    return response.data;
  }

  async saveEntity(investmentId: string, data: object) {
    const response = await this.post(`/v2/vault/entity/${investmentId}`, data);
    return response.data;
  }

  async saveMetadata(investmentId: string, data: object) {
    const response = await this.post(`/v2/vault/metadata/${investmentId}`, data);
    return response.data;
  }

  async search(params : { search : string, status: string, page: number}) {
    const response = await this.get("/v2/vault/search", { params });
    return response.data;
  }

}

export default VaultClient;
