import VaultClient from "../clients/VaultClient";
import { useApi } from "./useApi";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


/**
 * onMutateVault will update the cache within react-query for vault records,
 * this will prevent the need to constantly call refetchVault on components
 * when values are already available in memory from an update.
 *
 * @param client
 * @param updatePayload
 * @param investmentId
 */
export const onMutateVault = async (client: any, updatePayload: object, investmentId: string) => {
  await client.cancelQueries(['investment-vault', investmentId]);
  const previousVault: object | undefined = client.getQueryData(['investment-vault', investmentId]);
  const newVault = {
    ...previousVault,
    ...updatePayload
  }
  client.setQueryData(['investment-vault', investmentId], newVault);

  return {previousVault, newVault};
}

/**
 *
 * @param client
 * @param investmentId
 * @param error
 * @param newVault
 * @param context
 */
export const onErrorVault = async (client: any, investmentId: string, error: any, newVault: object, context: any) => {
  client.setQueryData(
    ['investment-vault', investmentId],
    context.previousVault
  )
}

export const useGetVault = (vaultId: string | null, options = {}) => {
  const vaultApi = useApi(VaultClient);

  return useQuery(
    ["investment-vault", vaultId],
    () => vaultApi.getVault(vaultId),
    {
      enabled: !!vaultId,
      ...options,
    }
  );
};

export const useGetUserVaults = (
  userId: string,
  status?: string,
  options = {}
) => {
  const vaultApi = useApi(VaultClient);

  return useQuery(
    ["user-vaults", userId],
    () => vaultApi.getUserVaults(userId, status),
    {
      enabled: !!userId,
      ...options,
    }
  );
};

export const useSaveVaultProfile = (investmentId: string, options = {}) => {
  const client = useQueryClient();
  const vaultApi = useApi(VaultClient);

  return useMutation(
    (data: Record<string, any>) => vaultApi.saveProfile(investmentId, data),
    {
      onMutate: async (payload) => await onMutateVault(client, payload, investmentId),
      onError: async (err, newData, context) => await onErrorVault(client, investmentId, err, newData, context),
      ...options
    }
  );
}

export const useSaveVaultAddress = (investmentId: string, options = {}) => {
  const client = useQueryClient();
  const vaultApi = useApi(VaultClient);

  return useMutation(
    (data: Record<string, any>) => vaultApi.saveAddress(investmentId, data),
    {
      onMutate: async (payload) => await onMutateVault(client, payload, investmentId),
      onError: async (err, newData, context) => await onErrorVault(client, investmentId, err, newData, context),
      ...options
    }
  );
}

export const useSaveVaultEntity = (investmentId: string, options = {}) => {
  const client = useQueryClient();
  const vaultApi = useApi(VaultClient);

  return useMutation(
    (data: Record<string, any>) => vaultApi.saveEntity(investmentId, data),
    {
      onMutate: async (payload) => await onMutateVault(client, payload, investmentId),
      onError: async (err, newData, context) => await onErrorVault(client, investmentId, err, newData, context),
      ...options
    }
  );
}


export const useSaveVaultMetadata = (investmentId: string, options = {}) => {
  const client = useQueryClient();
  const vaultApi = useApi(VaultClient);

  return useMutation(
    (data: Record<string, any>) => vaultApi.saveMetadata(investmentId, data),
    {
      onMutate: async (payload) => await onMutateVault(client, payload, investmentId),
      onError: async (err, newData, context) => await onErrorVault(client, investmentId, err, newData, context),
      ...options
    }
  );
}

