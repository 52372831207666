import { useEffect, useRef } from "react";

const SIMPLE_TITLE = "EquiDeFi";
const TITLE_FOR_ISSUER = "EquiDeFi Issuer Portal";
const TITLE_FOR_INVESTOR = "EquiDeFi Investor Portal";

const TITLE_MAP = {
  simple: SIMPLE_TITLE,
  issuer: TITLE_FOR_ISSUER,
  investor: TITLE_FOR_INVESTOR,
};

/**
 * @param {string | string[]} pieces
 */
export const useDocumentTitle = (pieces = [], titleType: keyof typeof TITLE_MAP = "simple") => {
  const prevTitle = useRef(document.title);

  useEffect(() => {
    try {
      /**
       * @type {string | string[]}
       *
       * This allows use the flexibility of passing in an array OR a single string to the
       * hook so that it allows for dynamic title pieces without needing to handle string
       * concatenation in other places
       */
      const start = Array.isArray(pieces) ? pieces : [pieces];
      const title = [...start.filter(Boolean)].filter(Boolean).join(" - ");

      document.title = [title, TITLE_MAP[titleType]].filter(Boolean).join(" | ");
    } catch (err) {
      document.title = prevTitle.current;
    }

    return () => {
      document.title = prevTitle.current;
    };
  }, []);
};
