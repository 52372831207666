import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel
} from "@chakra-ui/react";
import { Select as ReactSelect } from "chakra-react-select";
import { FC, forwardRef } from "react";
import { Options } from "react-select";
import { Text } from "../Text";

type TOption = {
  label: string;
  value: unknown;
};

export interface SelectProps {
  name: string;
  label: string;
  error?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  options?: Options<TOption>;
  defaultValue?: TOption;
  controlProps?: FormControlProps;
}

export const Select: FC<SelectProps> = forwardRef(
  (
    {
      name,
      label,
      isRequired = false,
      isInvalid = false,
      error,
      options = [],
      controlProps = { mb: 6 },
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl
        isRequired={isRequired}
        isInvalid={isInvalid}
        {...controlProps}
      >
        <FormLabel as={Text}>{label}</FormLabel>
        <ReactSelect
          name={name}
          isInvalid={isInvalid}
          options={options}
          {...rest}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  }
);
