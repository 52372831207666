export const ENTITY = "ENTITY";
export const INDIVIDUAL = "INDIVIDUAL";

export const ENTITY_TYPE = {
  [INDIVIDUAL]: "Individual (or Joint)",
  [ENTITY]: "Entity",
};

export const ENTITY_TYPE_OPTIONS = Object.entries(ENTITY_TYPE).map(
  ([value, label]) => ({ value, label })
);

export const US_CITIZEN = "US_CITIZEN";
export const INTERNATIONAL = "INTERNATIONAL";

export const CITIZENSHIP = {
  [US_CITIZEN]: "US Citizen",
  [INTERNATIONAL]: "International",
};

export const CITIZENSHIP_OPTIONS = Object.entries(CITIZENSHIP).map(
  ([value, label]) => ({ value, label })
);
