import
  {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    HStack,
    Tooltip,
    VStack,
  } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";

import { EntityTypeIcon } from "@equidefi/portals/components/EntityTypeIcon";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { useCreateInvestment } from "@equidefi/portals/hooks/useInvestments";
import { useGetUserVaults } from "@equidefi/portals/hooks/useVault";
import { APPROVED } from "@equidefi/shared/constants/accreditation";
import { capitalizeEveryWord } from "@equidefi/shared/helpers/string";
import { For, Paper, Text } from "@equidefi/ui";

import { useCanReinvest, useUserInvestments } from "@/hooks/useInvestments";
import { useWorkflowContext } from "@/pages/workflow/context/WorkflowContext";
import { WorkflowContainer } from "@/pages/workflow/WorkflowContainer";
import { Icon } from "@equidefi/ui/icon";

const VaultApprovedIcon = () => {
  return (
    <Flex
      as="span"
      align="center"
      justify="center"
      boxSize="1.1em"
      borderRadius="full"
      bg="equidefi.green"
      sx={{
        "& svg": {
          color: "white",
          m: "0",
          p: "0",
          mb: "-1px",
          strokeWidth: "3px",
        },
      }}
    >
      <Icon.Check size=".8em" />
    </Flex>
  );
};

const VaultRecord = ({ vault, onClick, canReinvest, isLoading = false }) => {
  useDocumentTitle(["Reinvest"]);

  if (!vault) return null;

  const isApproved = useMemo(
    () => vault?.status === "APPROVED",
    [vault?.status]
  );

  return (
    <Paper border="1px solid" borderColor="purple.400" w="full" p="4">
      <Flex direction="column" gap="1">
        <Flex direction="column" minW="full" gap="4">
          <HStack gap="4" alignItems="center">
            <EntityTypeIcon size="1.5em" type={vault.entity_type} />

            <Box>
              <Text textStyle="body" color="black" fontWeight="semibold" mb="0">
                <HStack>
                  <span>{vault.display_name}</span>
                  {isApproved && <VaultApprovedIcon />}
                </HStack>
              </Text>
              <Text mb="0">{capitalizeEveryWord(vault.entity_type)}</Text>
            </Box>
          </HStack>

          <Text mb="0" textStyle="body">
            {vault.phone_number}
          </Text>

          <Box as="address" mb="0">
            <Text m="0">{vault.address_street_1}</Text>
            {vault.address_street_2 && (
              <Text m="0">{vault.address_street_2}</Text>
            )}
            <Text m="0">
              {vault.address_city}, {vault.address_state}{" "}
              {vault.address_postal_code}
            </Text>
            <Text m="0">{vault.address_country_code}</Text>
          </Box>
          <Text textStyle="context" color="equidefi.darkGray" mb="2">
            <Tooltip
              label={moment(vault?.create_date).format("LLL")}
              placement="end"
              hasArrow
            >
              Created on: {moment(vault?.create_date).format("L")}
            </Tooltip>
          </Text>
        </Flex>

        {isApproved && canReinvest && (
          <Button
            textTransform="capitalize"
            fontWeight="semibold"
            w="full"
            isLoading={isLoading}
            onClick={() => onClick(vault)}
          >
            Continue with this Information
          </Button>
        )}
      </Flex>
    </Paper>
  );
};

export default function WorkflowReinvest() {
  const navigate = useNavigate();
  const { offering, user } = useWorkflowContext();
  const { data: vaults, isLoading } = useGetUserVaults(user.id, APPROVED);
  const createInvestment = useCreateInvestment();
  const [cookies] = useCookies(["bcode"]);

  const { data: investments, isFetchedAfterMount } = useUserInvestments(
    {
      offering_id: offering?.id,
    },
    { refetchOnMount: "always" }
  );
  const canReinvest = useCanReinvest(investments);

  useEffect(() => {
    if (!canReinvest && isFetchedAfterMount) {
      navigate("../choose", { replace: true });
    }
  }, [canReinvest, isFetchedAfterMount]);

  const onSelectAccreditation = async (vault) => {
    const response = await createInvestment.mutateAsync({
      offering_id: offering.id,
      vault_id: vault.id,
      bcode: cookies.bcode,
    });
    navigate(`/offerings/${offering.slug}/${response.id}/subscription`);
  };

  const onCreateNewVault = async () => {
    const response = await createInvestment.mutateAsync({
      offering_id: offering.id,
      bcode: cookies.bcode,
    });
    navigate(`/offerings/${offering.slug}/${response.id}/profile`);
  };

  return (
    <WorkflowContainer
      title="Reuse Your Information"
      buttonProps={{
        isLoading: isLoading,
      }}
    >
      <Text>
        Thank you for reinvesting! You can save time by reusing previously
        provided investor details.
      </Text>
      <VStack gap={4}>
        <For
          each={vaults?.records}
          fallback={
            <Alert status="warning">
              <AlertIcon />
              <AlertDescription textStyle="body">
                No reusable information is available, but you can still start a
                new investment from scratch.
              </AlertDescription>
            </Alert>
          }
        >
          {(vault) => (
            <VaultRecord
              key={vault.id}
              vault={vault}
              onClick={onSelectAccreditation}
              isLoading={createInvestment.isLoading}
              canReinvest={canReinvest}
            />
          )}
        </For>
        <Text textStyle="body" mb={0}>
          If you need to make a change to the information shown, you must create
          a new investment from scratch.
        </Text>
        <Button
          w="full"
          borderWidth="1px"
          fontWeight="semibold"
          textTransform="capitalize"
          variant="outline"
          onClick={onCreateNewVault}
          isLoading={createInvestment.isLoading}
          isDisabled={!canReinvest}
        >
          Start Investment from Scratch
        </Button>
      </VStack>
    </WorkflowContainer>
  );
}
