import
  {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
  } from "@chakra-ui/react";
import { useFormik } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

import { Heading, Input } from "@equidefi/ui";
import { WorkflowButton } from "../../../portals/investor/src/components/workflow/WorkflowButton";
import { PhoneField } from "./Auth";

const userSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required("Please enter your first name"),
  last_name: Yup.string().nullable().required("Please enter your last name"),
  phone: Yup.string().test({
    name: "phone",
    message: "Must be a valid phone number",
    test: (value) => (!!value ? isValidPhoneNumber(value) : false),
  }),
  email: Yup.string()
    .nullable()
    .email("Must be a valid email")
    .required("Please enter your email address"),
});

export const SignerModal = ({
  initialValues = {},
  isOpen = false,
  onHide,
  onSubmit,
}) => {
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema: userSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  if (!isOpen) {
    return null;
  }

  const isEdit = !!initialValues?.email;

  return (
    <Modal
      isOpen
      onClose={() => {
        resetForm();
        onHide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />

      <ModalContent mx="4">
        <ModalHeader>
          <Heading fontWeight="bold" mb="0">
            {isEdit ? "Edit Signer" : "Add Another Signer"}
          </Heading>
          <ModalCloseButton color="equidefi.red" />
        </ModalHeader>

        <ModalBody>
          <form>
            <Input
              label="First Name"
              placeholder="e.g. John"
              isRequired
              error={touched.first_name && errors.first_name}
              isInvalid={errors.first_name && touched.first_name}
              {...getFieldProps("first_name")}
            />
            <Input
              label="Middle Name"
              error={touched.middle_name && errors.middle_name}
              isInvalid={errors.middle_name && touched.middle_name}
              {...getFieldProps("middle_name")}
            />
            <Input
              label="Last Name"
              placeholder="e.g. Smith"
              isRequired
              error={touched.last_name && errors.last_name}
              isInvalid={errors.last_name && touched.last_name}
              {...getFieldProps("last_name")}
            />
            <Input
              label="Email"
              type="email"
              placeholder="e.g. john@equidefi.com"
              isRequired
              error={touched.email && errors.email}
              autoCapitalize="none"
              isInvalid={errors.email && touched.email}
              {...getFieldProps("email")}
            />
            <PhoneField
              error={touched?.phone && errors?.phone}
              setFieldValue={setFieldValue}
              {...getFieldProps("phone")}
            />
          </form>
        </ModalBody>

        <ModalFooter>
          <WorkflowButton isDisabled={!isValid} onClick={handleSubmit}>
            {isEdit ? "Save" : "Add signer"}
          </WorkflowButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
