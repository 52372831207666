import
  {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Flex,
  } from "@chakra-ui/react";
import { MAXIMUM_STRIPE_AMOUNT } from "@equidefi/shared/constants/payments";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import
  {
    useInitiatePaymentMutation,
    useSetupManualInvestmentPayment,
  } from "@/hooks/useInvestments";
import { useOfferingSettings } from "@/hooks/useOfferings";
import { useWorkflowContext } from "@/pages/workflow/context/WorkflowContext";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { Text } from "@equidefi/ui";
import { CurrencyFormatter } from "../../../constants/format";
import { PAYMENT_CATEGORY_MAP } from "../../../constants/payments";
import { stripeAccountPromise, stripePromise } from "../../../constants/stripe";
import { isRegD506COffering } from "../../../helpers";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowStepError from "../WorkflowStepError";
import { PaymentAccordion } from "./PaymentAccordion";
import { PaymentStepContext } from "./context";

export const WorkflowPayment = () => {
  const { investment, offering, analytics, refetchInvestment } =
    useWorkflowContext();
  const navigate = useNavigate();
  useWorkflowPosition(3);
  const { data: settings } = useOfferingSettings(offering.id);

  useEffect(() => {
    refetchInvestment();
  }, []);

  const setupManualPayment = useSetupManualInvestmentPayment(investment?.id);

  const settingsMap = useMemo(() => {
    const settingValue = (key) =>
      settings?.find((s) => s.id === key)?.value ?? "";

    return {
      bank_name: settingValue("payment_bank_name"),
      account_name: settingValue("payment_account_name"),
      account_number: settingValue("payment_account_number"),
      routing_number: settingValue("payment_routing_number"),
      transfer_agent_name: settingValue("transfer_agent_company"),
      wireInstructions: settingValue("manual_payment_wire_instructions"),
      checkInstructions: settingValue("manual_payment_check_instructions"),
    };
  }, [settings]);

  const createPayment = useInitiatePaymentMutation(investment?.id);

  useDocumentTitle([
    "Payment",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  const [paymentCategory, setPaymentCategory] = useState(null);

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [stripe, setStripe] = useState(null);

  const handleSectionChange = (index) => {
    const type = PAYMENT_CATEGORY_MAP[index];
    setPaymentCategory(type);
    if (type === "STRIPE") setupStripePayment();
  };

  const setupStripePayment = async () => {
    if (clientSecret) return null;

    try {
      const clientSecretData = await createPayment.mutateAsync();

      if (offering.stripe_account_id) {
        setStripe(stripeAccountPromise(offering.stripe_account_id));
      } else {
        setStripe(stripePromise);
      }
      setClientSecret(clientSecretData.clientSecret);
      setPaymentIntentId(clientSecretData.id);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePayment = async () => {
    analytics.track("payment", { type: "manual" });

    try {
      await setupManualPayment.mutateAsync({
        category: "MANUAL",
        payment_type: paymentCategory.toLowerCase(),
      });

      refetchInvestment();

      if (investment.expedited) {
        navigate(`/offerings/${offering.slug}/${investment?.id}/agreement`);
      } else {
        navigate(`/offerings/${offering.slug}/${investment?.id}/address`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const supportsStripePayment = investment?.amount <= MAXIMUM_STRIPE_AMOUNT;
  const wrongStep = !["PAYMENT", "SUBSCRIPTION"].includes(investment.next);

  const paymentHasSubmitted =
    investment?.payments?.filter(
      (p) => p.status === "created" && p.category === "MANUAL"
    ).length > 0 ||
    investment?.payments
      ?.map((p) => p.status)
      ?.includes(
        "requires_capture",
        "processing",
        "succeeded",
        "requires_action"
      ).length > 0 ||
    wrongStep;

  if (paymentHasSubmitted) {
    return (
      <WorkflowStepError
        onResume={() =>
          navigate(
            `/offerings/${offering.slug}/${investment.id}/${
              !wrongStep ? "address" : investment.next.toLowerCase()
            }`
          )
        }
        title="Payment"
        text="Your payment has already been submitted. You can check the status of your payment in your portfolio."
      />
    );
  }

  return (
    <PaymentStepContext.Provider
      value={{
        paymentIntentId,
        clientSecret,
      }}
    >
      <WorkflowContainer title="Payment" buttonLabel="Pay">
        <Box>
          <Text textStyle="context" mb={0} fontWeight="bold">
            {offering?.issuer.name}
          </Text>
          <Flex justify="space-between">
            <Text textStyle="context">
              {investment?.quantity} shares x{" "}
              {CurrencyFormatter.format(investment?.price)}
            </Text>

            <Text textStyle="body2">
              {CurrencyFormatter.format(investment?.amount)}
            </Text>
          </Flex>
          <Alert
            status="warning"
            borderRadius="lg"
            border="1px solid"
            borderColor="equidefi.yellow"
            boxShadow="sm"
            mb={2}
          >
            <AlertIcon alignSelf="flex-start" />
            <AlertDescription>
              <Text textStyle="body2" fontWeight="bold">
                Please read carefully
              </Text>
              {isRegD506COffering(offering.exemption_type) && (
                <Text textStyle="body2">
                  <strong>YOU MUST BE AN ACCREDITED INVESTOR</strong>, which
                  means you will be able to provide proof showing $200,000 in
                  income in 2022 and 2023 for individuals or $300,000 of joint
                  income or a copy of a bank or brokerage statement showing $1
                  million in assets net of liabilities and exclusive of
                  residence, or a pro letter.
                </Text>
              )}
              <Text textStyle="bold" mb={0} pb={0}>
                <strong>Refunds</strong>
              </Text>
              <Text textStyle="body2">
                If you do not meet the requirements for this offering, you will
                be notified on how to receive a refund. Please note that your
                refund can take up to seven days to be processed.
              </Text>
              <Text textStyle="bold" mb={0} pb={0}>
                <strong>Investment Review</strong>
              </Text>
              <Text textStyle="body2">
                Kindly note that it can take 7-10 business days to review your
                paperwork for final approval. Please be patient through this
                process and we will reach out to you directly should we need to
                be in touch with you.
              </Text>
            </AlertDescription>
          </Alert>
          <Text textStyle="context" mt={0} mb={4}>
            CC/ACH fees are paid for by the company
          </Text>
        </Box>

        <PaymentAccordion
          onSubmitManualPayment={handlePayment}
          supportsStripePayment={supportsStripePayment}
          onSectionChange={handleSectionChange}
          stripe={stripe}
          clientSecret={clientSecret}
          isManualPaymentLoading={setupManualPayment.isLoading}
          paymentSettings={settingsMap}
          investment={investment}
          offering={offering}
        />
      </WorkflowContainer>
    </PaymentStepContext.Provider>
  );
};
