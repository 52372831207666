import { Icon } from "@equidefi/ui/icon";
import React, { useMemo } from "react";

type TEntityTypeIconProps = {
  type?: "INDIVIDUAL" | "ENTITY" | null;
  size?: string;
  fallback?: React.ReactNode;
};

export const EntityTypeIcon: React.FC<TEntityTypeIconProps> = ({
  type,
  size = "1em",
  fallback,
}) => {
  const _fallback = useMemo(() => {
    return fallback || <Icon.HelpCircle size={size} />;
  }, [fallback, size]);

  return type === "INDIVIDUAL" ? (
    <Icon.User size={size} />
  ) : type === "ENTITY" ? (
    <Icon.Briefcase size={size} />
  ) : (
    _fallback
  );
};
