import { useInvestment } from "@/hooks/useInvestments";
import { useOfferingAnalytics } from "@/hooks/useOfferingAnalytics";
import { useOfferingV2 } from "@/hooks/useOfferings";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { createContext, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";

export const WorkflowContext = createContext({
  position: 0,
  setPosition: () => {},
  refetchInvestment: () => {},
});

export const WorkflowProvider = ({ children }) => {
  const params = useParams();
  const { slug, investment_id: investmentId } = params;

  const { data: user } = useCurrentUser();

  const { data: offering, isError: offeringError } = useOfferingV2(slug);
  const analytics = useOfferingAnalytics(offering);

  const {
    data: investment,
    isLoading: investmentIsLoading,
    refetch: refetchInvestment,
  } = useInvestment(investmentId);

  const startUri = useMemo(() => {
    let next = investment?.next;

    if (investmentIsLoading || !investment || !next) return null;

    // Don't try to route to these pages, they don't exist for the investor.
    if (["CLOSING", "REFUND", "COUNTERSIGN", "DILIGENCE"].includes(next)) {
      return null;
    } else {
      // Determine initial uri to load
      return next.toLowerCase();
    }
  }, [investmentIsLoading, investment?.next, investment]);

  const [position, setPosition] = useState(1);

  return (
    <WorkflowContext.Provider
      value={{
        position,
        setPosition,
        user,
        offering,
        investment,
        analytics,
        refetchInvestment,
        startUri,
        investmentIsLoading,
        isError: offeringError,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflowContext = () => {
  return useContext(WorkflowContext);
};
