import
  {
    Alert,
    IconButton,
    useDisclosure,
    useToast,
    useToken,
  } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";

import { MAX_ADDITIONAL_SIGNERS } from "@equidefi/shared/constants/investments";
import { ENTITY_TYPE_OPTIONS } from "@equidefi/shared/constants/profile";
import { CardList, CardListItem, Heading, Password, Text } from "@equidefi/ui";
import { Input } from "@equidefi/ui/forms";
import RadioGroup from "@equidefi/ui/forms/RadioGroup";
import { Icon } from "@equidefi/ui/icon";

import { useWorkflowContext } from "@/pages/workflow/context/WorkflowContext";
import { SignerModal } from "@equidefi/portals/components/SignerModal";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { useStartSignatureRequest } from "@equidefi/portals/hooks/useSignatures";
import { useInvestmentSigners } from "@equidefi/portals/hooks/useSigners";
import { useSaveVaultEntity } from "@equidefi/portals/hooks/useVault";
import { userFullName } from "@equidefi/shared";
import { DateField } from "@equidefi/ui/forms/DateField";
import moment from "moment";
import { MESSAGE } from "../../../../../issuer/src/constants/forms";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowForm from "../WorkflowForm";

const schema = Yup.object().shape({
  company: Yup.string()
    .nullable()
    .when("entity_type", {
      is: "ENTITY",
      then: Yup.string().trim().required(MESSAGE.REQUIRED("Entity Name")),
    }),
  entity_type: Yup.string().nullable().required("Please select an entity type"),
  tax_id: Yup.string()
    .nullable()
    .when("entity_type", {
      is: "INDIVIDUAL",
      then: Yup.string()
        .trim()
        .matches(
          /^\d{9}$/,
          "Must be exactly 9 digits, numbers only (no dashes)"
        )
        .required(MESSAGE.REQUIRED("Social Security or Tax ID")),
    }),
  birthdate: Yup.date()
    .nullable()
    .when("entity_type", {
      is: "INDIVIDUAL",
      then: Yup.date()
        .max(moment().subtract(18, "years").add(1, "day").toDate())
        .required(
          "Please include your birthdate, and you must be at least 18 years old."
        ),
    }),
  ein: Yup.string()
    .nullable()
    .when("entity_type", {
      is: "ENTITY",
      then: Yup.string()
        .trim()
        .matches(
          /^\d{9}$/,
          "Must be exactly 9 digits, numbers only (no dashes)"
        )
        .required(MESSAGE.REQUIRED("EIN")),
    }),
});

const helperText = {
  INDIVIDUAL: {
    text: "Select this option for Individual, Joint Tenants, Custodian/Minor",
    header: "Individual or Joint Investors",
  },
  ENTITY: {
    text: "An entity may be a Trust, Corporation, Partnership, LLC, etc…",
    header: "Entity",
  },
};

const DEFAULT_DATA = {
  company: null,
  entity_type: "INDIVIDUAL",
  birthdate: null,
};

export const WorkflowSigners = () => {
  const { investment, offering, user, refetchInvestment } =
    useWorkflowContext();
  useWorkflowPosition(5);
  const [equidefiRed, equidefiBlue] = useToken("colors", [
    "equidefi.red",
    "equidefi.blue",
  ]);
  const toast = useToast();
  const [spinner, showSpinner] = useState(false);
  const [isDeletingSigners, setIsDeletingSigners] = useState(false);
  const navigate = useNavigate();

  useDocumentTitle(["Signers", offering?.name, investment?.issuer?.name]);

  const entityUpdate = useSaveVaultEntity(investment?.id);
  const signatureRequest = useStartSignatureRequest();
  const modal = useDisclosure();

  const onSubmit = async (data) => {
    showSpinner(true);

    try {
      await entityUpdate.mutateAsync({
        company: data.company,
        entity_type: data.entity_type,
        tax_id: data.tax_id,
        birthdate: data.birthdate,
        ein: data.ein,
      });

      await signatureRequest.mutateAsync({ investmentId: investment.id });

      refetchInvestment();

      navigate(`/offerings/${offering.slug}/${investment?.id}/kyc`);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description:
          error.response?.data?.errors?.join(". ") ?? "Something went wrong",
      });
    } finally {
      showSpinner(false);
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    getFieldProps,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: DEFAULT_DATA,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  const {
    signers,
    onEdit,
    onCreate,
    onDelete,
    onDeleteMultipleSigners,
    onSubmit: onSubmitModal,
    initialValues,
  } = useInvestmentSigners(investment?.id, user?.entity_type, {
    onSuccess: () => {
      modal.onClose();
    },
  });

  const canAddMoreSigners = useMemo(
    () => signers.length < MAX_ADDITIONAL_SIGNERS[values.entity_type],
    [signers.length, values.entity_type]
  );

  const updateForIndividual = useCallback(async () => {
    if (isDeletingSigners) return;

    setFieldValue("company", null);

    const maxIndividualSigners = MAX_ADDITIONAL_SIGNERS["INDIVIDUAL"];
    if (signers.length <= maxIndividualSigners) return;

    const excessSignerIds = signers
      .slice(maxIndividualSigners)
      .map((signer) => signer?.id)
      .filter(Boolean);

    if (excessSignerIds.length > 0) {
      try {
        setIsDeletingSigners(true);
        await onDeleteMultipleSigners(excessSignerIds);
      } catch (error) {
        console.error("Failed to delete excess signers:", error);
      } finally {
        setIsDeletingSigners(false);
      }
    }
  }, [signers, isDeletingSigners]);

  useEffect(() => {
    if (values?.entity_type !== "INDIVIDUAL") return;

    updateForIndividual();
  }, [values.entity_type]);

  return (
    <WorkflowContainer
      title="Signers"
      onContinue={handleSubmit}
      buttonLabel={signers.length > 0 ? "Continue" : "Skip"}
      buttonProps={{
        isDisabled: !isValid,
        isLoading: spinner,
      }}
    >
      <WorkflowForm onSubmit={handleSubmit}>
        <Text>Are you investing as an individual or an entity?</Text>
        <RadioGroup
          fieldName="entity_type"
          defaultValue={values.entity_type || "INDIVIDUAL"}
          options={ENTITY_TYPE_OPTIONS}
          errors={touched.entity_type && errors}
          setFieldValue={setFieldValue}
        />
        {values.entity_type ? (
          <Alert
            status="info"
            flexDirection="column"
            borderRadius="8"
            border="1px solid"
            borderColor="equidefi.blue"
            mb="8"
            alignItems="start"
            padding="4"
          >
            <Heading fontWeight="bold" m="0" textStyle="h3">
              {helperText[values.entity_type].header}
            </Heading>
            <Text m="0">{helperText[values.entity_type].text}</Text>
          </Alert>
        ) : null}

        {values?.entity_type === "ENTITY" && (
          <Input
            label="Entity Name"
            placeholder="e.g. Acme Corporation"
            isRequired
            error={touched.company && errors.company}
            isInvalid={errors.company && touched.company}
            {...getFieldProps("company")}
          />
        )}
        {values?.entity_type === "INDIVIDUAL" && (
          <>
            <Password
              label="SSN/TIN"
              isRequired
              maxLength={9}
              error={touched.tax_id && errors.tax_id}
              isInvalid={errors.tax_id && touched.tax_id}
              checkboxHintText="Show SSN/TIN"
              placeholder="Please enter your SSN/TIN without '-'"
              {...getFieldProps("tax_id")}
            />
            <DateField
              label="Date of Birth"
              name="birthdate"
              dateFormat="yyyy-MM-dd"
              value={values.birthdate}
              onChange={(date) => {
                setFieldValue("birthdate", date);
                touched.birthdate = true;
              }}
              onBlur={() => {
                touched.birthdate = true;
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  touched.birthdate = true;
                }
              }}
              error={errors.birthdate && touched.birthdate ? errors.birthdate : undefined}
              isInvalid={errors.birthdate && touched.birthdate}
              showTimeSelect={false}
              showMonthDropdown={true}
              showYearDropdown={true}
              maxDate={(() => moment().subtract(18, "years").toDate())()}
              isRequired
            />
          </>
        )}
        {values?.entity_type === "ENTITY" && (
          <Password
            label="EIN"
            placeholder="Please enter your EIN without '-'"
            isRequired
            maxLength={9}
            error={touched.ein && errors.ein}
            isInvalid={errors.ein && touched.ein}
            checkboxHintText="Show EIN"
            {...getFieldProps("ein")}
          />
        )}
      </WorkflowForm>
      <Text m="0">Add any required additional signers</Text>
      <CardList my="4">
        <CardListItem>
          <Heading my="2">{userFullName(investment?.vault)}</Heading>
          <Text textStyle="context" m="0">
            {user?.email}
          </Text>
          <Text textStyle="context" mb="2">
            {user?.phone_number}
          </Text>
        </CardListItem>
        {signers.map((value, i) => (
          <CardListItem
            key={value.id || i}
            action={
              <IconButton
                onClick={() => onDelete(value.id)}
                icon={<Icon.Trash size="1.5em" color={equidefiRed} />}
              />
            }
            secondaryAction={
              <IconButton
                onClick={() => {
                  onEdit(value);
                  modal.onOpen();
                }}
                icon={<Icon.Edit size="1.5em" color={equidefiBlue} />}
              />
            }
          >
            <Heading my="2">{userFullName(value)}</Heading>
            <Text textStyle="context" m="0">
              {value.email}
            </Text>
            <Text textStyle="context" mb="2">
              {value.phone_number}
            </Text>
          </CardListItem>
        ))}
        {canAddMoreSigners && (
          <CardListItem
            key="add"
            onClick={() => {
              onCreate();
              modal.onOpen();
            }}
            action={<IconButton icon={<Icon.Plus size="1.5em" />} />}
          >
            <Text textStyle="context" my="2">
              Add another signer
            </Text>
          </CardListItem>
        )}
      </CardList>

      <SignerModal
        isOpen={modal.isOpen}
        initialValues={initialValues}
        onHide={modal.onClose}
        onSubmit={onSubmitModal}
      />
    </WorkflowContainer>
  );
};
