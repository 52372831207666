import {
  FormControl,
  FormLabel,
  FormLabelProps,
  InputGroup,
  Select,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { FC, forwardRef, useCallback } from "react";
import { getMonth, getYear } from "date-fns";
import { Input } from "./Input";
import { Icon } from "../icon";
// @ts-ignore - Using ts-ignore for react-datepicker to avoid type issues
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DateFieldProps {
  name: string;
  value?: Date | null;
  onChange?: (date: Date | null) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  label: string;
  helperText?: string;
  error?: string;
  labelProps?: FormLabelProps;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  mb?: string | number;
}

// Create our own range function
const range = (start: number, end: number, step: number = 1): number[] => {
  const length = Math.floor((end - start) / step) + 1;
  return Array.from({ length }, (_, index) => start + index * step);
};

const years = range(1900, getYear(new Date()) + 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Define types for the renderCustomHeader props
interface RenderCustomHeaderProps {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

export const DateField: FC<DateFieldProps> = forwardRef(
  (
    {
      name,
      value,
      error,
      isInvalid,
      onChange,
      onBlur,
      onKeyDown,
      label,
      helperText,
      isDisabled = false,
      isRequired = false,
      labelProps = {},
      showTimeSelect = false,
      dateFormat,
      minDate,
      maxDate,
      placeholder = "Select date",
      showYearDropdown = false,
      showMonthDropdown = false,
      mb = "2",
      ...rest
    },
    ref
  ) => {
    const defaultDateFormat = showTimeSelect
      ? "MMMM d, yyyy h:mm aa"
      : "MMMM d, yyyy";

    // Handle key down events to prevent form submission on Enter
    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        // Prevent form submission on Enter
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
        }
        
        // Call the provided onKeyDown handler if it exists
        if (onKeyDown) {
          onKeyDown(e);
        }
      },
      [onKeyDown]
    );

    // Handle change events to ensure validation happens
    const handleChange = useCallback(
      (date: Date | null) => {
        if (onChange) {
          onChange(date);
        }
        
        // Trigger onBlur to ensure validation happens
        if (onBlur) {
          setTimeout(() => {
            onBlur();
          }, 0);
        }
      },
      [onChange, onBlur]
    );

    // We need to use any here because of the complex integration between
    // Chakra UI's Input component and react-datepicker
    const inputProps: any = {
      as: DatePicker,
      ref: ref as any,
      name,
      selected: value,
      onChange: handleChange,
      onBlur,
      onKeyDown: handleKeyDown,
      dateFormat: dateFormat || defaultDateFormat,
      minDate,
      maxDate,
      showTimeSelect,
      placeholderText: placeholder,
      showMonthDropdown,
      showYearDropdown,
      yearDropdownItemNumber: 60,
      scrollableYearDropdown: true,
      popperPlacement: "top-start",
      error,
      isInvalid: !!error || isInvalid,
      shouldCloseOnSelect: true,
      disabledKeyboardNavigation: false,
      renderCustomHeader: ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Flex px="2" py="2" alignItems="center" justifyContent="space-between">
          <IconButton
            size="sm"
            onClick={decreaseMonth}
            isDisabled={prevMonthButtonDisabled}
            variant="ghost"
            // Prevent form submission when clicking month navigation
            type="button"
            icon={<Icon.ChevronLeft />}
            aria-label="Previous month"
          />
          <Flex gap="2">
            <Select
              size="sm"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              // Prevent form submission when changing month 
              onClick={(e) => e.stopPropagation()}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>

            <Select
              size="sm"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(Number(value))}
              // Prevent form submission when changing year
              onClick={(e) => e.stopPropagation()}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </Flex>
          <IconButton
            size="sm"
            onClick={increaseMonth}
            isDisabled={nextMonthButtonDisabled}
            variant="ghost"
            // Prevent form submission when clicking month navigation
            type="button"
            icon={<Icon.ChevronRight />}
            aria-label="Next month"
          />
        </Flex>
      ),
      sx: {
        ".react-datepicker-wrapper": {
          width: "100%",
        },
        ".react-datepicker": {
          border: "1px solid",
          borderColor: "gray.200",
          borderRadius: "md",
          fontFamily: "inherit",
        },
        ".react-datepicker__header": {
          background: "white",
          borderBottom: "1px solid",
          borderBottomColor: "gray.200",
        },
        ".react-datepicker__day--selected": {
          backgroundColor: "purple.500",
          color: "white",
        },
        ".react-datepicker__day--keyboard-selected": {
          backgroundColor: "purple.100",
          color: "purple.500",
        },
        ".react-datepicker__day:hover": {
          backgroundColor: "purple.50",
        },
        ".react-datepicker__triangle": {
          left: "-32px !important",
        },
      },
      popperModifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 12],
          },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: "viewport",
            padding: 12,
            altAxis: true,
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top-start", "bottom-start"],
            padding: 12,
          },
        },
      ],
      ...rest
    };

    return (
      <FormControl
        isDisabled={isDisabled}
        isRequired={isRequired}
        isInvalid={!!error || isInvalid}
        mt="1"
        mb={mb}
      >
        <FormLabel mt="0" p="0" fontSize="md" {...labelProps}>
          {label}
        </FormLabel>
        <InputGroup>
          <Input {...inputProps} />
        </InputGroup>
      </FormControl>
    );
  }
);

DateField.displayName = "DateField";
