import { Badge, Box, Flex, HStack, Image, VStack } from "@chakra-ui/react";
import { offeringIsCompleted } from "@equidefi/shared";
import { Heading, Text } from "@equidefi/ui";
import { CurrencyFormatter } from "../../constants/format";
import Continue from "./continue";
import {BannerTextMap, BannerTypeMap} from "@/constants/investment";


const StatusBanner = ({ action }) => {
  return (
    <Badge
      h="6"
      lineHeight="6"
      fontSize="14px"
      justifyContent="center"
      bgColor={BannerTypeMap[action] || "equidefi.yellow"}
      w="full"
      textAlign="center"
    >
      {BannerTextMap[action]}
    </Badge>
  );
};

const InvestmentCard = ({
  investment,
  issuer,
  offering,
  cardAction,
  hideStatusBanner = false,
}) => {
  if (!investment) {
    return null;
  }

  const issuerData = issuer || investment?.issuer;
  const offeringData = offering || investment?.offering;

  const isOfferingCompleted = offeringIsCompleted(offeringData);

  const status = investment?.status;

  return (
    <Box
      w="full"
      h="auto"
      borderRadius="8px"
      bg="linear-gradient(150deg, black 0%, #3e3e3e 100%)"
      p="4"
      color="white"
      onClick={cardAction}
      cursor={cardAction ? "pointer" : null}
    >
      {!hideStatusBanner && (
        <StatusBanner w="full" textAlign="center" action={status} />
      )}

      <VStack gap="2.5" minW="full" mt="3">
        <Flex minW="full">
          <Image
            mr="2.5"
            mb="0"
            w="50px"
            h="50px"
            borderRadius="4px"
            src={issuerData?.photo_url}
            alt={"logo"}
          />
          <Box>
            <Heading mb="0" textStyle="h3" fontWeight="bold">
              {issuerData?.name}
            </Heading>
            <HStack>
              <Text
                m="0"
                textStyle="h2"
                fontWeight="bold"
              >{`${CurrencyFormatter.format(investment?.amount)}`}</Text>
              <Text m="0"> {` • ${investment?.quantity} shares`}</Text>
            </HStack>
          </Box>
        </Flex>

        <Text
          mb="0"
          ml={{ base: 1, lg: 0 }}
          textStyle="body2"
          alignSelf="start"
        >
          {offeringData?.name}
        </Text>

        {!hideStatusBanner && !isOfferingCompleted && (
          <Continue investment={investment} offering={offeringData} />
        )}
      </VStack>
    </Box>
  );
};

export default InvestmentCard;
