export const CurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const NumberFormatter = new Intl.NumberFormat("en-US");

export const USDFormatter = (value: number): string => {
  // Removes the `$` from the beginning of the string and adds a space for UI purposes
  // TODO: Explore different options for other formatting approaches.
  return CurrencyFormatter.format(value).replace(/^(\D+)/, "$1 ");
};
