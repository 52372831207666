import { Badge, Box, Flex, Tooltip } from "@chakra-ui/react";
import moment from "moment";
import NumberFormat from "react-number-format";

import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import
  {
    PAYMENT_FAILED_STATUSES,
    PAYMENT_STATUS_DISPLAY,
  } from "@/constants/payments";

const PAYMENT_CATEGORY_TEXT = {
  wire: "Wire Transfer",
  card: "Credit Card Payment",
  us_bank_account: "ACH Payment",
  cashapp: "Cashapp",
  check: "Check Payment",
};

const PAYMENT_CATEGORY_ICON = {
  wire: Icon.DollarSign,
  card: Icon.CreditCard,
  us_bank_account: Icon.Archive,
  cashapp: Icon.CreditCard,
  check: Icon.Mail,
};

export const PaymentRow = ({ payment }) => {
  const badgeConfig = PAYMENT_STATUS_DISPLAY[payment.status];

  const capturePaymentExpired = payment.expiration_date < moment();

  const PaymentIcon = PAYMENT_CATEGORY_ICON[payment.payment_type];
  return (
    <Box as="tr" key={payment.id}>
      <Box as="td" verticalAlign="baseline">
        <Tooltip
          label={
            payment.category === "STRIPE" && payment.payment_method_display
          }
        >
          <>
            <Flex align="center" gap="1">
              <Box as={PaymentIcon} flexShrink="0" size="1em" />

              <Text m="0">{PAYMENT_CATEGORY_TEXT[payment.payment_type]}</Text>
            </Flex>
            <Text textStyle="context" m="0">
              {payment.payment_method_display}
            </Text>
          </>
        </Tooltip>
      </Box>
      <Box as="td" verticalAlign="baseline">
        <Tooltip
          label={moment(payment.create_date).format("MMM DD, YYYY h:mma ZZ")}
        >
          <Box as="span" whiteSpace="nowrap">
            {moment(payment.create_date).format("ll")}
          </Box>
        </Tooltip>
      </Box>
      <Box as="td" verticalAlign="baseline">
        {badgeConfig && (
          <Tooltip label={badgeConfig.description(payment)}>
            <Badge fontSize="0.65em" colorScheme={badgeConfig.color}>
              {typeof badgeConfig.title === "string"
                ? badgeConfig.title
                : badgeConfig.title(payment)}
            </Badge>
          </Tooltip>
        )}
        {payment?.status === "requires_capture" && (
          <Text textStyle="subtext" mb={0}>
            {capturePaymentExpired
              ? "Expired"
              : `Capturable before ${moment(payment.expiration_date).format(
                  "ll"
                )}`}
          </Text>
        )}
      </Box>
      <Box as="td" textAlign="end" verticalAlign="baseline">
        <NumberFormat
          value={payment.amount}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={2}
          thousandSeparator
          prefix="$"
          renderText={(value, props) => (
            <Text
              as={PAYMENT_FAILED_STATUSES.includes(payment.status) && "s"}
              {...props}
            >
              {value}
            </Text>
          )}
        />
      </Box>
    </Box>
  );
};
