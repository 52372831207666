import { anatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const parts = anatomy("dataSummaryCard").parts(
  "container",
  "card",
  "content",
  "title",
  "mainText",
  "secondaryText"
);

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  container: {
    display: "flex",
    borderRadius: "lg",
    minWidth: "150px",
    w: "100%",
    border: "1px solid",
    overflow: "hidden",
    alignItems: "stretch",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    w: "100%",
    p: 4,
    gap: 3,
    borderRadius: "lg",
  },
  title: {
    fontSize: "sm",
    textTransform: "uppercase",
    color: "gray.900",
  },
  mainText: {
    fontSize: "md",
    fontWeight: "bold",
    color: "gray.900",
    lineHeight: "1.3",
  },
  secondaryText: {
    mt: -3,
    fontSize: "md",
    color: "gray.900",
  },
});

const variants = {
  unknown: definePartsStyle({
    container: {
      borderColor: "gray.500",
    },
    card: {
      bg: "gray.100", // secondary with opacity
      _hover: {
        bg: "gray.50",
        cursor: "pointer",
      },
    },
  }),
  secondary: definePartsStyle({
    container: {
      borderColor: "secondary.500",
    },
    card: {
      bg: "secondary.100", // secondary with opacity
      _hover: {
        bg: "secondary.50",
        cursor: "pointer",
      },
    },
  }),
  ready: definePartsStyle({
    container: {
      borderColor: "green.500",
    },
    card: {
      bg: "green.100", // green with opacity
      _hover: {
        bg: "green.50",
        cursor: "pointer",
      },
    },
  }),
  review: definePartsStyle({
    container: {
      borderColor: "yellow.500",
    },
    card: {
      bg: "yellow.100", // yellow with opacity
      _hover: {
        bg: "yellow.50",
        cursor: "pointer",
      },
    },
  }),
  info: definePartsStyle({
    container: {
      borderColor: "blue.500",
    },
    card: {
      bg: "blue.100", // blue with opacity
      _hover: {
        bg: "blue.50",
        cursor: "pointer",
      },
    },
  }),
};

const sizes = {
  sm: definePartsStyle({
    mainText: {
      fontSize: "md",
    },
  }),
  md: definePartsStyle({
    mainText: {
      fontSize: "lg",
    },
  }),
  lg: definePartsStyle({
    mainText: {
      fontSize: "xl",
    },
  }),
};

export const dataSummaryCardTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
});
