import { Box, Flex, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { CardList, CardListItem, Heading, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

const DocumentReview = ({ documents }) => {
  if (documents.length < 1) return null;

  return (
    <>
      <Heading textStyle="h1" fontWeight="bold" mb={0}>
        Documents
      </Heading>
      <Text>
        Please read through all the documents carefully before you continue.
      </Text>
      <CardList mb="3">
        {documents?.map((document, index) => (
          <LinkBox key={index} _target="blank">
            <CardListItem
              _hover={{
                filter: "brightness(90%)",
              }}
            >
              <Flex w="full" justify="space-between" align="center">
                <LinkOverlay
                  href={document.url}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Download document ${document.type}`}
                >
                  <Text m={0}>{document.type}</Text>
                </LinkOverlay>
                <Box as="span" color="equidefi.blue">
                  <Icon.Link2 size="1.2em" aria-label="Link icon" />
                </Box>
              </Flex>
            </CardListItem>
          </LinkBox>
        ))}
      </CardList>
    </>
  );
};

export default DocumentReview;
