import { Box, BoxProps, forwardRef, useMultiStyleConfig } from "@chakra-ui/react";
import { ReactNode } from "react";
import { V2Theme } from "../../theme";

export interface DataSummaryCardProps extends BoxProps {
  variant?: 
    | "unknown" 
    | "ready" 
    | "review" 
    | "info" 
    | "secondary"
  size?: "sm" | "md" | "lg";
  title: string;
  mainText: ReactNode;
  secondaryText?: ReactNode;
}

export const DataSummaryCard = forwardRef<DataSummaryCardProps, "div">(
  (props, ref) => {
    const { variant = "unknown", title, mainText, secondaryText, size = "md", ...rest } = props;
    const styles = useMultiStyleConfig("DataSummaryCard", { variant, size, theme: V2Theme });

    return (
      <Box __css={styles.container} {...rest}>
        <Box ref={ref} __css={styles.card}>
          <Box __css={styles.title}>{title}</Box>
          <Box __css={styles.mainText}>{mainText}</Box>
          {secondaryText && (
            <Box __css={styles.secondaryText}>{secondaryText}</Box>
          )}
        </Box>
      </Box>
    );
  }
);

DataSummaryCard.displayName = "DataSummaryCard"; 