import
  {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Center,
    Flex,
    Spinner,
  } from "@chakra-ui/react";
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useReactToPrint } from "react-to-print";

import { ReadMore } from "@equidefi/portals/components/ReadMore";
import InvestmentProgress from "@equidefi/portals/components/investments/InvestmentProgress";
import InvestmentSignatureList from "@equidefi/portals/components/investments/InvestmentSigantureList";
import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { offeringIsCompleted } from "@equidefi/shared";
import { Heading, Text } from "@equidefi/ui";

import InvestmentCard from "@/components/investment/InvestmentCard";
import { ReinvestBox } from "@/components/investment/ReinvestBox";
import { OfferingTerminated } from "@/components/offerings/OfferingTerminated";
import { useInvestment } from "@/hooks/useInvestments";

import InvestmentDisplayItem from "./InvestmentDisplayItem";
import InvestmentNdaCopy from "./InvestmentNdaCopy";
import ManualPaymentInstructions from "./ManualPaymentInstructions";
import OfferingAgreementsList from "./OfferingAgreementsList";
import OfferingInfoCard from "./OfferingInfoCard";
import OfferingInvestorPackageList from "./OfferingInvestorPackageList";
import PaymentsCard from "./PaymentsCard";

const AccordionSection = ({ title, children, hidden }) => {
  return (
    <AccordionItem mt="4" hidden={hidden} maxWidth="full">
      <Box borderRadius="lg" py="2">
        <AccordionButton>
          <Heading textStyle="h3" fontWeight="bold" m="0">
            {title}
          </Heading>
          <AccordionIcon ml="auto" />
        </AccordionButton>
        <AccordionPanel>{children}</AccordionPanel>
      </Box>
    </AccordionItem>
  );
};

const InvestmentOverview = () => {
  useDocumentTitle(["Investment Overview"]);

  const dispatch = useDispatch();
  const { id } = useParams();
  const ndaRef = useRef(null);

  const { data: investment, isFetchedAfterMount } = useInvestment(id, {
    refetchOnMount: "always",
  });
  const [accordionIndex, setAccordionIndex] = useState(0);
  const { offering, issuer } = isFetchedAfterMount ? investment : {};

  const isOfferingCompleted = offeringIsCompleted(offering);
  const isInvestmentRejected = investment?.status === "REJECTED";

  const handlePrintNda = useReactToPrint({
    content: () => ndaRef.current,
    documentTitle: `${investment?.user.full_name} - NDA.pdf`,
  });

  const [spinner, showSpinner] = useState(false);

  const { data, isFetchedAfterMount: areSignaturesFetched } =
    useInvestmentSignatures(investment?.id, {
      refetchOnMount: "always",
    });

  const agreement = data?.investment_agreement;
  const parties = data?.signing_parties;
  const remindableId = data?.remindable?.id;

  const investors = useMemo(
    () => parties?.filter((p) => !p.countersigner) ?? [],
    [parties]
  );

  useEffect(() => {
    dispatch({ type: "offering", payload: offering });
  }, [dispatch, investment, offering]);

  const downloadAgreement = (agreement) => {
    showSpinner(true);
    axios
      .get(`investment/${investment?.id}/agreement/${agreement.envelope_id}`)
      .then((response) => {
        let toBytes = new Uint8Array(response.data.data),
          toBinary = ``;
        toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));

        const file = window.btoa(toBinary);
        FileSaver.saveAs(
          `data:application/pdf;base64,${file}`,
          agreement.name.replace(/[^a-zA-Z ]/g, "")
        );

        showSpinner(false);
      });
  };

  const reverse = (item) => {
    return [...item].reverse();
  };

  if (!isFetchedAfterMount || !areSignaturesFetched) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      w="full"
      p="4"
      align="start"
      gap="4"
    >
      <Box p="0" w="full">
        <InvestmentCard
          investment={investment}
          issuer={issuer}
          offering={offering}
        />
        {isFetchedAfterMount && (
          <InvestmentProgress investment={investment} offering={offering} />
        )}

        {isOfferingCompleted && <OfferingTerminated />}
        <Accordion
          variant="card"
          defaultIndex={0}
          index={accordionIndex}
          onChange={setAccordionIndex}
          allowToggle
          maxWidth={"full"}
        >
          <AccordionSection title="Investment Details">
            <InvestmentDisplayItem
              label="Amount Invested"
              value={
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={2}
                  decimalScale={2}
                  prefix="$"
                  value={investment?.amount}
                />
              }
            />
            <InvestmentDisplayItem
              label="Price per Share"
              value={
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={2}
                  decimalScale={2}
                  prefix="$"
                  value={investment?.price}
                />
              }
            />{" "}
            <InvestmentDisplayItem
              label="Number of Shares"
              value={
                <NumberFormat
                  thousandSeparator
                  displayType="text"
                  value={investment?.quantity}
                />
              }
            />
            <InvestmentDisplayItem
              label="Investment Date"
              value={moment(investment?.create_date).format("MM/DD/yyyy")}
            />
          </AccordionSection>
          <AccordionSection
            title="Investment Signers"
            hidden={!investors.length}
          >
            <InvestmentSignatureList
              investmentId={agreement?.investment_id}
              signingParties={investors}
              remindableId={remindableId}
            />
          </AccordionSection>
          <AccordionSection title="Company Business Description">
            <InvestmentDisplayItem
              value={<ReadMore text={offering?.description} maxLength={500} />}
            />
            <InvestmentDisplayItem label="Issuer CIK" value={issuer?.cik} />
            <InvestmentDisplayItem
              label="Issuer EIN"
              value={
                issuer?.ein
                  ? `${issuer?.ein.substring(0, 2)}-${issuer?.ein.substring(3)}`
                  : "N/A"
              }
            />
            <InvestmentDisplayItem label="Issuer SIC" value={issuer?.sic} />
            <InvestmentDisplayItem
              label="Doing Business As"
              value={issuer?.doing_business_as}
            />
            {issuer?.address_street_1 && (
              <InvestmentDisplayItem
                label="Mailing Address"
                value={
                  <>
                    {issuer?.address_street_1}
                    <br />
                    {issuer?.address_street_2 ? (
                      <>
                        {issuer?.address_street_2}
                        <br />
                      </>
                    ) : null}
                    {issuer?.address_city}, {issuer?.address_state}{" "}
                    {issuer?.address_postal_code}
                  </>
                }
              />
            )}
            {issuer?.business_street_1 && (
              <InvestmentDisplayItem
                label="Business Address"
                value={
                  <>
                    {issuer?.business_street_1}
                    <br />
                    {issuer?.business_street_2 ? (
                      <>
                        {issuer?.business_street_2}
                        <br />
                      </>
                    ) : null}
                    {issuer?.business_city}, {issuer?.business_state}{" "}
                    {issuer?.business_postal_code}
                  </>
                }
              />
            )}
          </AccordionSection>

          <AccordionSection
            title="Payment Details"
            hidden={!investment?.payments?.length}
          >
            <PaymentsCard
              hideAction={isOfferingCompleted || isInvestmentRejected}
              payments={reverse(investment?.payments ?? [])}
              setAccordionIndex={setAccordionIndex}
            />
          </AccordionSection>

          <AccordionSection
            title="Manual Payment Instructions"
            hidden={investment?.payments?.at(0)?.category !== "MANUAL"}
          >
            <ManualPaymentInstructions investment={investment} />
          </AccordionSection>

          <AccordionSection title="Agreements">
            <OfferingAgreementsList
              offering={offering}
              data={data}
              investment={investment}
              isLoading={spinner}
              onPrintNda={handlePrintNda}
              onDownloadAgreement={(agreement) => downloadAgreement(agreement)}
            />
          </AccordionSection>

          <AccordionSection
            title="Investor Package Download"
            hidden={!offering?.documents?.length}
          >
            <OfferingInvestorPackageList
              documents={offering?.documents ?? []}
            />
          </AccordionSection>

          <AccordionSection title="Offering Details">
            <OfferingInfoCard offering={offering} />
          </AccordionSection>
        </Accordion>

        {!isOfferingCompleted && (
          <ReinvestBox
            offeringId={offering?.id}
            offeringSlug={offering?.slug}
          />
        )}

        <Box hidden p="3">
          <Box ref={ndaRef} p="6">
            <Heading>Non-Disclosure Agreement</Heading>
            <InvestmentNdaCopy
              name={offering?.issuer?.name}
              state={issuer?.address?.state}
            />
            <hr />
            <Text>Name: {investment?.user?.full_name}</Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default InvestmentOverview;
