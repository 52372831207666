import {
  StyleConfig,
  defineStyle,
  extendTheme,
  withDefaultColorScheme,
  type ColorMode,
  type ThemeConfig,
} from "@chakra-ui/react";
import { PaperTheme } from "../Paper/PaperTheme";

// v2 components
import { dataSummaryCardTheme } from "../v2/DataSummaryCard/DataSummaryCardTheme";
import { investmentProgressChipsTheme } from "../v2/InvestmentProgressChips/InvestmentProgressChipsTheme";
import { investmentSummaryBoxTheme } from "../v2/InvestmentSummaryBox/InvestmentSummaryBoxTheme";
import { tableTheme } from "../v2/Table/TableTheme";
import { tabsTheme } from "../v2/Tabs/TabsTheme";

import { textStyles } from "./text-styles";

const BaseTheme = extendTheme(
  {
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
      disableTransitionOnChange: true,
    } as ThemeConfig,
    styles: {
      global: ({ colorMode }: { colorMode: ColorMode }) => {
        return {
          "html, body": {
            backgroundColor: colorMode === "dark" ? "gray.900" : "#f9fbfd",
          },
        };
      },
    } as StyleConfig,
    colors: {
      secondary: "#7283a0",
      primary: "#172a4c",
      accent: "#7147bd",
      background: "#f0f5fa",
      equidefi: {
        darkGray: "#7283a0",
        red: "#ca0000",
        green: "#7eaa6d",
        yellow: "#f5ac4c",
        blue: "#3959de",
      },
      purple: {
        50: "#f1ecf9",
        100: "#d8c9ee",
        200: "#bea6e3",
        300: "#a583d8",
        400: "#8c60cd",
        500: "#723dc2",
        600: "#5c319b",
        700: "#452574",
        800: "#2e194d",
        900: "#170c27",
      },
    },
    components: {
      Progress: {
        variants: {
          filled: {
            filledTrack: {
              backgroundColor: "#ffffff",
              background:
                "linear-gradient(rgba(132, 132, 132, 0.4), rgba(219, 219, 219, 0.4))",
            },
            track: {
              zIndex: 10,
              height: "10px",
              borderRadius: "5px",
            },
          },
          unfilled: {
            track: {
              height: "3.33px",
              backgroundColor: "#ffffff",
              opacity: "50%",
              borderRightRadius: "5px",
            },
          },
        },
      },
      Accordion: {
        variants: {
          card: defineStyle({
            container: {
              borderRadius: "lg",
              background: "white",
              boxShadow: "card",
              my: 1,
            },
            button: {
              _hover: { bg: "", filter: "brightness(90%)" },
              py: 2,
              px: 4,
              accentColor: "#3959DE",
            },
          }),
        },
      },
      Table: {
        baseStyle: {
          td: {
            verticalAlign: "baseline",
            borderWidth: 0,
          },
          thead: {
            tr: {
              borderBottomWidth: "1px",
              borderColor: "#e7e9ec",
            },
          },
          tbody: {
            tr: {
              borderBottomWidth: "1px",
              borderColor: "#e7e9ec",
            },
            td: {
              paddingTop: "0.75rem",
              paddingBottom: "0.75rem",
            },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          fontSize: "base",
          fontWeight: "normal",
        },
      },
      Paper: PaperTheme,
      Input: {
        baseStyle: {
          field: {
            bg: "white",
            background: "white",
          },
        },
      },
      Textarea: {
        baseStyle: defineStyle({
          bg: "white",
          background: "white",
        }),
      },
      Button: {
        baseStyle: defineStyle({
          fontWeight: "normal",
        }),
        variants: {
          outline: defineStyle({
            borderWidth: "2px",
          }),
          secondary: defineStyle({
            backgroundColor: "#7283a0",
            _hover: {
              filter: "brightness(90%)",
            },
            _active: {
              filter: "brightness(50%)",
            },
            color: "white",
            transition: "0.1s",
          }),
        },
      },
    },
    shadows: {
      paper: "0px 0px 30px 0px rgba(0, 0, 0, 0.10)",
      card: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    },
  },
  withDefaultColorScheme({ colorScheme: "purple" })
);

export const EquidefiTheme = extendTheme(BaseTheme, {
  textStyles,
  fonts: {
    heading: `"Cerebri Sans", -apple-system, "system-ui", Helvetica, Arial, sans-serif`,
    body: `"Cerebri Sans", -apple-system, "system-ui", Helvetica, Arial, sans-serif`,
  },
});

export const V2Theme = extendTheme(BaseTheme, {
  colors: {
    primary: {
      50: "#f1ecf8",
      100: "#d6caec",
      200: "#bca9e0",
      300: "#a287d4",
      400: "#8865c8",
      500: "#6e43bc",
      600: "#583696",
      700: "#422871",
      800: "#2c1b4b",
      900: "#160d26",
    },
    secondary: {
      50: "#f0f2f5",
      100: "#d4d9e2",
      200: "#b9c1d0",
      300: "#9da9bd",
      400: "#8291ab",
      500: "#677998",
      600: "#52617a",
      700: "#3e495b",
      800: "#29303d",
      900: "#15181e",
    },
    gray: {
      50: "#f2f2f2",
      100: "#dbdbdb",
      200: "#c4c4c4",
      300: "#adadad",
      400: "#969696",
      500: "#808080",
      600: "#666666",
      700: "#4d4d4d",
      800: "#333333",
      900: "#1a1a1a",
    },
    blue: {
      50: "#e9edfb",
      100: "#c2ccf5",
      200: "#9aabee",
      300: "#738ae8",
      400: "#4c69e1",
      500: "#2448db",
      600: "#1d39af",
      700: "#162b83",
      800: "#0f1d57",
      900: "#070e2c",
    },
    green: {
      50: "#f1f6ef",
      100: "#d7e5d2",
      200: "#bdd4b5",
      300: "#a4c398",
      400: "#8ab27b",
      500: "#71a15e",
      600: "#5a814b",
      700: "#446138",
      800: "#2d4026",
      900: "#172013",
    },
    yellow: {
      50: "#fef4e7",
      100: "#fbe0bb",
      200: "#f9cb90",
      300: "#f6b764",
      400: "#f4a339",
      500: "#f28f0d",
      600: "#c1720b",
      700: "#915608",
      800: "#613905",
      900: "#301d03",
    },
    red: {
      50: "#faebeb",
      100: "#f0c7c7",
      200: "#e6a2a2",
      300: "#dd7e7e",
      400: "#d35a5a",
      500: "#ca3535",
      600: "#a12b2b",
      700: "#792020",
      800: "#511515",
      900: "#280b0b",
    },
  },

  components: {
    Checkbox: {
      baseStyle: {
        control: {
          bg: "white",
        },
      },
    },
    Card: {
      baseStyle: {
        container: {
          borderRadius: "0.8125em",
        },
      },
    },
    Tabs: tabsTheme,
    Table: tableTheme,
    InvestmentSummaryBox: investmentSummaryBoxTheme,
    InvestmentProgressChips: investmentProgressChipsTheme,
    DataSummaryCard: dataSummaryCardTheme,
  },
  textStyles,
  fonts: {
    heading: `"Figtree", sans-serif`,
    body: `"Figtree", sans-serif`,
  },
});
