import * as Yup from "yup";
import { CurrencyFormatter, NumberFormatter } from "../../../constants/format";
import { formatPhoneNumber } from "@equidefi/shared/helpers/string";

const MAX_AMOUNT = 15_000_000;

export const subscriptionSchema = ({
  offering,
  investment,
  isOfferingRegA,
}) => {
  const unitPrice = Number(offering?.unit_price) || 1;
  const minUnits = offering?.investor_minimum_units;
  const minAmount = offering?.investor_minimum_units * unitPrice;

  const standardSchema = Yup.object({
    accredited: Yup.boolean().required().oneOf([true]),
    quantity: Yup.number()
      .test({
        message: `Quantity is required`,
        test: (quantity) => !!quantity,
      })
      .test({
        message: `Quantity must not be a decimal`,
        test: (quantity) => Number(quantity) === Math.round(quantity),
      })
      .test({
        message: `Minimum ${NumberFormatter.format(minUnits)} shares required`,
        test: (quantity) => !(quantity > 0 && quantity < minUnits),
      })
      .max(
        MAX_AMOUNT / unitPrice,
        `If you would like to invest more than ${CurrencyFormatter.format(
          MAX_AMOUNT
        )}, please contact the issuer at ${formatPhoneNumber(
          offering?.issuer?.phone
        )}`
      ),
    amount: Yup.number()
      .test({
        message: `Amount is required`,
        test: (amount) => !!amount,
      })
      .test({
        message: `Minimum ${CurrencyFormatter.format(
          minUnits * unitPrice
        )} investment required`,
        test: (value) => !(value > 0 && value < minAmount),
      }),
    selectedBroker: Yup.object({
      label: Yup.string().nullable(),
      value: Yup.object().nullable(),
    }).nullable(),
  });

  const incomeSchema = Yup.object({
    accredited: Yup.boolean(),
    annual_income: Yup.number().when("accredited", {
      is: false,
      then: (schema) =>
        schema.required(
          `You must provide an annual income if you are not accredited`
        ),
      otherwise: (schema) => schema.nullable(),
    }),
    quantity: Yup.number()
      .required(`Quantity is required`)
      .min(
        minUnits,
        `Minimum ${NumberFormatter.format(minUnits)} shares required`
      )
      .test({
        message: `Quantity must not be a decimal`,
        test: (quantity) => Number(quantity) === Math.round(quantity),
      })
      .when(
        ["accredited", "annual_income"],
        (accredited, annualIncome, schema) => {
          if (!!accredited) return schema;

          const maxQuantity = Math.round(
            ((investment?.annual_income || annualIncome) ?? 0) /
              (10 * unitPrice)
          );
          return schema.max(
            maxQuantity,
            `Based on your stated annual income, your number of shares must be less than ${maxQuantity}`
          );
        }
      )
      .max(
        MAX_AMOUNT / unitPrice,
        `If you would like to invest more than ${CurrencyFormatter.format(
          MAX_AMOUNT
        )}, please contact the issuer at ${offering?.issuer?.phone}`
      ),
    amount: Yup.number()
      .required(`Investment amount is required`)
      .min(
        minAmount,
        `Minimum ${CurrencyFormatter.format(minAmount)} investment required`
      ),
    selectedBroker: Yup.object({
      label: Yup.string().nullable(),
      value: Yup.object().nullable(),
    }).nullable(),
  });

  return isOfferingRegA ? incomeSchema : standardSchema;
};
