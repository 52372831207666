import { Box, IconButton, useClipboard, useToast } from "@chakra-ui/react";

import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

const CopyableText = ({ text }) => {
  if (!text) return null;
  const clipboard = useClipboard(text);
  const toast = useToast();
  // const label = clipboard.hasCopied ? "Copied!" : "Copy to Clipboard";

  const handleClick = () => {
    clipboard.onCopy();
    toast({ status: "info", description: "Copied to clipboard!", icon: null });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      alignContent="center"
      textStyle="context"
      mb="0"
      w="full"
    >
      <Text
        isTruncated
        fontSize="1em"
        maxWidth={{ base: "full", md: "12em" }}
        mb="0"
      >
        {text}
      </Text>
      <IconButton
        size="1em"
        mx="3"
        onClick={handleClick}
        variant="none"
        icon={<Icon.Copy size=".8em" />}
      />
    </Box>
  );
};

export default CopyableText;
